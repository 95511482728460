import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <main className="errorPage">
      <title>Not found</title>
      <div className="page-section">
        <h1 className="error-heading">Sorry, we can’t seem to find the page you’re looking for.</h1>
        <p className="error-text">
          Seems we’ve encountered a problem. We know… it’s a tough pill to swallow, but please bear with us.
          <br />
          <br />
          Here are some helpful links instead:
        </p>
        <p className="helpful-links">
          <Link to="/how-aidia-works">How Aidia Works</Link>
          <Link to="/faqs?lang=EN">FAQs</Link>
          <Link to="/faqs?lang=ES">Preguntas frecuentes</Link>
          <Link to="/evidence">Evidence</Link>
        </p>
      </div>
    </main>
  )
}

export default NotFoundPage
